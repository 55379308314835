export const SNAKE_GAME_COLOUR_UPDATE = 'SNAKE::SNAKE_GAME_COLOUR_UPDATE';
export const SNAKE_GAME_SPEED_UPDATE = 'SNAKE::SNAKE_GAME_SPEED_UPDATE';
export const SNAKE_GAME_VELOCITY_UPDATE = 'SNAKE::SNAKE_GAME_VELOCITY_UPDATE';
export const SNAKE_GAME_TICK = 'SNAKE::SNAKE_GAME_TICK';
export const SNAKE_GAME_PAUSE = 'SNAKE::SNAKE_GAME_PAUSE';
export const SNAKE_GAME_UNPAUSE = 'SNAKE::SNAKE_GAME_UNPAUSE';
export const SNAKE_GAME_DISPLAY = 'SNAKE::SNAKE_GAME_DISPLAY';
export const SNAKE_GAME_DEATH = 'SNAKE::SNAKE_GAME_DEATH';
export const SNAKE_SUBMIT_DISPLAY = 'SNAKE::SNAKE_SUBMIT_DISPLAY';
export const SNAKE_SUBMIT_NAME_UPDATE = 'SNAKE::SNAKE_SUBMIT_NAME_UPDATE';
